/* eslint-disable prettier/prettier */
import { Site } from '../../../../corelogic/domain/models/site/site.model';

export const siteFixture: Site = {
  _id: '1',
  name: 'Saint-Avold Unit 7',
  siteGroupReferences: [
    {
      name: 'Ambient Temperature',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Ambient Temperature',
          value: 17.5,
          unit: '°C',
        },
      ],
    },
    {
      name: 'Ambient Pressure',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Ambient Pressure',
          value: 17.5,
          unit: 'mbar',
        },
      ],
    },
    {
      name: 'Relative Humidity',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Relative Humidity',
          value: 85.3,
          unit: '%',
        },
      ],
    },
    {
      name: 'Grid Frequency',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Grid Frequency',
          value: 17.5,
          unit: '%',
        },
      ],
    },
    {
      name: 'Lower Heating Value and C/H',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'lower Heating Value',
          value: 17.5,
          unit: 'MJ/KG',
        },
        {
          name: 'C/H Ratio',
          value: 17.5,
          unit: '%',
        },
      ],
    },
    {
      name: 'Excess GT inlet Pressure Drop',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Excess GT inlet Pressure Drop',
          value: 17.5,
          unit: 'mbar',
        },
      ],
    },
    {
      name: 'Anti-icing Dew Point Temperature',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Delta Inlet Temperature Increase',
          value: 17.5,
          unit: '°C',
        },
        {
          name: 'Fixed Set Point Temperature',
          value: 17.5,
          unit: '°C',
        },
      ],
    },
    {
      name: 'Anti-icing Fixed Temperature - Relative Humidity',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Threshold Temperature',
          value: 17.5,
          unit: '°C',
        },
        {
          name: 'Threshold Relative Humidity',
          value: 17.5,
          unit: '%',
        },
        {
          name: 'Set Point Temperature',
          value: 17.5,
          unit: '°C',
        },
      ],
    },
    {
      name: 'Degradation',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'EOH',
          value: 17.5,
          unit: 'h',
        },
      ],
    },
    {
      name: 'Sulfur Content',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Sulfur Content',
          value: 17.5,
          unit: 'mg/Nm3',
        },
      ],
    },
    {
      name: 'GT Exhaust Pressure Drop',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'GT Exhaust Pressure Drop',
          value: 17.5,
          unit: 'mbar',
        },
      ],
    },
    {
      name: 'Wind Speed',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Wind Speed',
          value: 17.5,
          unit: 'm/s',
        },
      ],
    },
    {
      name: 'GT Fuel Gas Inlet Temperature',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'GT Fuel Gas Inlet Temperature',
          value: 17.5,
          unit: '°C',
        },
      ],
    },
    {
      name: 'Fogging',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Fogging',
          value: 17.5,
          unit: '°C',
        },
      ],
    },
    {
      name: 'Load Limit',
      last_modification: '2023-05-13T13:22:14.916Z',
      references: [
        {
          name: 'Max Power CCGT',
          value: 17.5,
          unit: 'MW',
        },
        {
          name: 'Max Power GT',
          value: 17.5,
          unit: 'MW',
        },
      ],
    },
  ],
  correction_parameters: [
    {
      name: 'Ambient Pressure',
      correction: [
        {
          name: 'Power Output',
          last_update: '2023-05-15T13:22:14.916Z',
          data: {
            x1: [1.5, 3.0, 4.5, 10.2],
            y: [1.2, 1, 0.9, 0.8],
          },
        },
        {
          name: 'Heat Rate',
          last_update: '2023-05-15T13:22:14.916Z',
          data: {
            x1: [1.5, 3.0, 4.5, 10.2],
            y: [1.2, 1, 0.9, 0.8],
          },
        },
      ],
    },
    {
      name: 'Relative Humidity',
      correction: [
        {
          name: 'Power Output',
          last_update: '2023-05-15T13:22:14.916Z',
          data: {
            x1: [-3, -2, 3, 8],
            x2: [15.1, 30, 45, 102],
            y: [1, 9, 4, 6],
          },
        },
        {
          name: 'Heat Rate',
          last_update: '2023-05-15T13:22:14.916Z',
          data: {
            x1: [-1, 4, 6, 15.3],
            x2: [15.1, 30, 45, 102],
            y: [-3, 7, 9, -2],
          },
        },
      ],
    },
    {
      name: 'Ambient Temperature',
      correction: [
        {
          name: 'Power Output',
          last_update: '2023-05-15T13:22:14.916Z',
          data: {
            x1: [-3, -2, 3, 8],
            x2: [15.1, 30, 45, 102],
            y: [1, 9, 4, 6],
          },
        },

        {
          name: 'Heat Rate',
          last_update: '2023-05-15T13:22:14.916Z',
          data: {
            x1: [-1, 4, 6, 15.3],
            x2: [15.1, 30, 45, 102],
            y: [-3, 7, 9, -2],
          },
        },
      ],
    },
  ],
  identity: {
    manufacturerName: 'potato',
    lastRevisionDate: '2020-05-15T13:22:14.916Z',
    powerOutput: 666,
    heatRate: 666,
    netHeatRate: 667,
    igvPosition: 666,
    lastMajorMaintenance: '2020-05-15T13:22:14.916Z',
    pMin: 50,
    pMinPercentage: 30,
    floor: 210,
    country: 'France',
  },
  tags: [
    { name: 'Ambient Temperature', tag: 'OZQDIZHDQOIHDZ', constant: null, lastModification: null, lowerBound:0, upperBound:1, default:0.5 },
    { name: 'Ambient Pressure', tag: 'AAAAAAA', constant: null, lastModification: null, lowerBound: 0, upperBound: 1, default: 0.5 },
    { name: 'Ambient Relative Humidity', tag: '', constant: 666, lastModification: null, lowerBound: 0, upperBound: 1, default: 0.5 },
  ],
  failures: [
    {
      description: 'Failures!',
      impactPower: 669,
      startDate: '2020-05-15T13:22:14.916Z',
      impactHeat: null,
      endDate: null,
    },
  ],
  mlModel: [
    {
      modelId: '1',
      name: 'P2M_Bayet_MLModel_PowerandHeatRate_01062023.xlsx',
      status: true,
      creationDate: '2023-06-01T13:22:14.916Z',
      modelType: 'Power',
      effectiveDate: '2023-06-01T14:22:14.916Z',
    },
    {
      modelId: '2',
      name: 'P2M_Bayet_MLModel_PowerandHeatRate_02062023.xlsx',
      status: true,
      creationDate: '2023-06-02T13:22:14.916Z',
      modelType: 'EfficiencyMax',
      effectiveDate: '2023-06-01T15:22:14.916Z',
    },
    {
      modelId: '3',
      name: 'P2M_Bayet_MLModel_PowerandHeatRate_03062023.xlsx',
      status: true,
      creationDate: '2023-06-03T13:22:14.916Z',
      modelType: 'EfficiencyMin',
      effectiveDate: null,
    },
  ],
  admins: [
    {
      name: 'john doe',
      jobTitle: 'worker',
      mail: 'john.doe@mail.com',
      mail_subscriber: false,
    },
  ],
  dataExclusion: [
    {
      description: 'explosion',
      startDate: new Date('2023-06-03T13:22:14.916Z'),
      endDate: new Date('2023-06-03T13:22:14.916Z'),
      applyToEfficiency: false,
      applyToPower: true,
    },
  ],
};

export const siteFixtureFailure = {
  _id: '',
  correction_parameters: [],
  name: '',
  dataExclusion: [],
  siteGroupReferences: [],
  identity: {
    floor: null,
    netHeatRate: 0,
    manufacturerName: '',
    lastRevisionDate: '',
    powerOutput: 0,
    heatRate: 0,
    igvPosition: 0,
    lastMajorMaintenance: '',
    pMin: null,
    pMinPercentage: null,
    country: null,
  },
  tags: [],
  failures: [],
  mlModel: [],
  admins: [],
};
