import { Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { CustomCard } from '../../../../../../util/functionUtil';
import Status from '../../generalDashboard/component/power/status/status';
import styles from './cardComponentStatus.module.scss';

type Props = { title: string };

const CardComponentStatus: React.FC<Props> = ({ title }) => {
  const { kpiImbalance } = useSelector((state: RootState) => state.kpiImbalance);
  const { graphIndex } = useSelector((state: RootState) => state.graphIndex);

  const currentKpi = kpiImbalance[graphIndex];

  return (
    <CustomCard boxShadow="unset">
      <div style={{ display: 'flex', width: '-webkit-fill-available' }}>
        <h3 className={styles.titleText}>
          <FormattedMessage id={title} />
        </h3>
        <div className={styles.statusList}>
          <Status
            icon="icon.bolt"
            status="generalDashboard.status.pmd"
            value={currentKpi?.pmd ? 'ON' : 'OFF'}
          />
          <Divider orientation="vertical" sx={{ borderRightWidth: 2 }} variant="middle" flexItem />
          <Status icon="icon.warning" status="generalDashboard.status.loadLimit" value={'OFF'} />
          <Divider orientation="vertical" sx={{ borderRightWidth: 2 }} variant="middle" flexItem />
          <Status
            icon="icon.waterDrop"
            status="generalDashboard.status.foggingValve"
            value={currentKpi?.fogging_valve?.toFixed(2).concat(' %') ?? 'N/A'}
          />
          <Divider orientation="vertical" sx={{ borderRightWidth: 2 }} variant="middle" flexItem />
          <Status
            icon="icon.acUnit"
            status="generalDashboard.status.antiIcing"
            value={currentKpi?.anti_icing_control ? 'ON' : 'OFF'}
          />
          <Divider orientation="vertical" sx={{ borderRightWidth: 2 }} variant="middle" flexItem />
          <Status
            icon="icon.speed"
            status="generalDashboard.status.igv"
            value={currentKpi?.igv?.toFixed(2).concat(' %') ?? 'N/A'}
          />
        </div>
      </div>
    </CustomCard>
  );
};

export default CardComponentStatus;
