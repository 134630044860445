import { Divider, useTheme } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../corelogic/redux/create-store';
import { Spinner } from '../../../../../../../../../ui/spinner/spinner.component';
import styleSpinner from '../../../../../../../../../ui/spinner/spinner.component.module.scss';
import { IMBALANCE_TYPE, PAGE } from '../../../../../type';
import ImbalanceDetail from '../ImbalanceDetails/imbalanceDetails';
import styles from './forecastImbalance.module.scss';

type Props = {
  isEfficiency?: boolean;
  showLoader: boolean;
  efficiencyCurveName?: string;
};

const ForecastImbalance: FC<Props> = ({ isEfficiency, showLoader, efficiencyCurveName }) => {
  const { kpiImbalance } = useSelector((state: RootState) => state.kpiImbalance);
  const { graphIndex } = useSelector((state: RootState) => state.graphIndex);
  const theme = useTheme();

  const isPowerForecastImbalance = () => {
    if (isEfficiency === false) {
      if (
        kpiImbalance[graphIndex]?.net_power_forecast_imbalance !== null &&
        kpiImbalance[graphIndex]?.net_power_forecast_imbalance != undefined
      ) {
        return (
          <div
            className={styles.value}
            data-full-value={
              kpiImbalance[graphIndex]?.net_power_forecast_imbalance?.toFixed(6) || ''
            }
          >
            {kpiImbalance[graphIndex]?.net_power_forecast_imbalance?.toFixed(2)}
          </div>
        );
      } else {
        return (
          <div className={styles.value}>
            <FormattedMessage id="generalDashboard.NA" />
          </div>
        );
      }
    } else if (efficiencyCurveName === PAGE.MAX_EFFICIENCY) {
      if (
        kpiImbalance[graphIndex]?.hhv_efficiency_forecast_imbalance !== null &&
        kpiImbalance[graphIndex]?.hhv_efficiency_forecast_imbalance !== undefined
      ) {
        return (
          <div
            className={styles.value}
            data-full-value={
              kpiImbalance[graphIndex]?.hhv_efficiency_forecast_imbalance?.toFixed(6) || ''
            }
          >
            {kpiImbalance[graphIndex]?.hhv_efficiency_forecast_imbalance?.toFixed(2)}
          </div>
        );
      } else {
        return (
          <div className={styles.value}>
            <FormattedMessage id="generalDashboard.NA" />
          </div>
        );
      }
    } else if (
      kpiImbalance[graphIndex]?.hhv_efficiency_min_forecast_imbalance !== null &&
      kpiImbalance[graphIndex]?.hhv_efficiency_min_forecast_imbalance !== undefined
    ) {
      return (
        <div
          className={styles.value}
          data-full-value={
            kpiImbalance[graphIndex]?.hhv_efficiency_min_forecast_imbalance?.toFixed(6) || ''
          }
        >
          {kpiImbalance[graphIndex]?.hhv_efficiency_min_forecast_imbalance?.toFixed(2)}
        </div>
      );
    } else {
      return (
        <div className={styles.value}>
          <FormattedMessage id="generalDashboard.NA" />
        </div>
      );
    }
  };

  return (
    <div style={{ width: 'inherit' }}>
      <div className={styles.forecastContainer}>
        <div className={styles.forecastImbalance}>
          <div className={styles.titleAndSubtitle}>
            <div className={styles.title}>
              <FormattedMessage id="generalDashboard.imbalances.subTitle" />
            </div>
            <div className={styles.logoAndSubtitle}>
              <span className={styles.material_icons}>
                <FormattedMessage id="icon.info" />
              </span>
              <div className={styles.subTitle} style={{ color: theme.palette.text.secondary }}>
                <FormattedMessage id="generalDashboard.imbalances.info" />
              </div>
            </div>
          </div>
          <div
            className={styles.valueAndUnit}
            style={{ fontSize: 16, color: theme.palette.text.primary }}
          >
            {showLoader === true ? (
              <Spinner className={styleSpinner.spinnerSmall} />
            ) : (
              isPowerForecastImbalance()
            )}
            <div className={styles.unit} style={{ color: theme.palette.text.secondary }}>
              <FormattedMessage id={isEfficiency === false ? 'unit.mw' : 'unit.pt%'} />
            </div>
          </div>
        </div>
        {!isEfficiency && (
          <div>
            <Divider textAlign="center">
              <FormattedMessage id="generalDashboard.imbalances.details" />
            </Divider>
            <div className={styles.detailsContainer}>
              <div className={styles.detailsColumnContainer}>
                <ImbalanceDetail
                  isTitle={true}
                  name={IMBALANCE_TYPE.weather}
                  value={kpiImbalance[graphIndex]?.weather_imbalance}
                  size={14}
                  showLoader={showLoader}
                />
                <ImbalanceDetail
                  isTitle={false}
                  name={IMBALANCE_TYPE.ambientTemperature}
                  value={kpiImbalance[graphIndex]?.temperature_imbalance}
                  size={12}
                  showLoader={showLoader}
                />
                <ImbalanceDetail
                  isTitle={false}
                  name={IMBALANCE_TYPE.ambientPressure}
                  value={kpiImbalance[graphIndex]?.pressure_imbalance}
                  size={12}
                  showLoader={showLoader}
                />
                <ImbalanceDetail
                  isTitle={false}
                  name={IMBALANCE_TYPE.ambientRelativeHumidity}
                  value={kpiImbalance[graphIndex]?.relative_humidity_imbalance}
                  size={12}
                  showLoader={showLoader}
                />
              </div>
              <div className={styles.detailsColumnContainer}>
                <ImbalanceDetail
                  isTitle={true}
                  name={IMBALANCE_TYPE.otherForecast}
                  value={kpiImbalance[graphIndex]?.total_others_imbalance}
                  size={14}
                  showLoader={showLoader}
                />
                <ImbalanceDetail
                  isTitle={false}
                  name={IMBALANCE_TYPE.networkFrequency}
                  value={kpiImbalance[graphIndex]?.network_frequency_imbalance}
                  size={12}
                  showLoader={showLoader}
                />
                <ImbalanceDetail
                  isTitle={false}
                  name={IMBALANCE_TYPE.gasLHVCHR}
                  value={
                    kpiImbalance[graphIndex]?.lower_heating_value_imbalance === undefined &&
                    kpiImbalance[graphIndex]?.c_h_ratio_imbalance === undefined
                      ? null
                      : (kpiImbalance[graphIndex]?.lower_heating_value_imbalance ?? 0) +
                        (kpiImbalance[graphIndex]?.c_h_ratio_imbalance ?? 0)
                  }
                  size={12}
                  showLoader={showLoader}
                />
                <ImbalanceDetail
                  isTitle={false}
                  name={IMBALANCE_TYPE.dp_filtre}
                  value={kpiImbalance[graphIndex]?.dp_filtre}
                  size={12}
                  showLoader={showLoader}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForecastImbalance;
