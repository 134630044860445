import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CardContent, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridComparatorFn } from '@mui/x-data-grid';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { PiTag } from '../../../../../corelogic/domain/models/pitag/pitag.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { getFormattedDateWithMinute } from '../../../../../helpers/dateFunction';
import { CustomButton, CustomCard, StripedDataGrid } from '../../../../../util/functionUtil';
import EditTagsPopin from '../editTagsPopin/editTagsPopin';
import styles from './tag-mapping.module.scss';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const TagMapping: FC = () => {
  const { site } = useSelector((state: RootState) => state.site);
  const { isAdmin } = useSelector((state: RootState) => state.isAdmin);
  const { menuStatus } = useSelector((state: RootState) => state.contextCurrentMenuStatus);

  const intl = useIntl();
  function copyData(data: PiTag) {
    let dataToCopy: string | undefined;
    if (data.tag?.length > 0) dataToCopy = data.tag;
    else dataToCopy = data.constant?.toString() ?? '';
    navigator.clipboard.writeText(dataToCopy);
  }

  const [tooltipValue, setTooltipValue] = useState<boolean[]>(
    new Array(site.tags.length > 0 ? site.tags.length : 40).fill(false),
  );
  const [open, setOpen] = useState(false);
  const handlePopinOpen = () => {
    setOpen(true);
  };

  const handlePopinClose = (publish?: boolean) => {
    if (publish) return setOpen(false);
    if (confirm(intl.formatMessage({ id: 'home.confirmNotSave' }))) setOpen(false);
  };

  function handleTooltipOpen(index: number) {
    setTooltipValue(prevArray => {
      const newArray = [...prevArray];
      newArray[index] = !newArray[index];
      return newArray;
    });
  }

  function handleTooltipClose() {
    const updatedArray = new Array(site.tags.length).fill(false);
    setTooltipValue(updatedArray);
  }

  const nameTranslatedComparator: GridComparatorFn<string> = (v1, v2) => {
    return intl
      .formatMessage({ id: 'tags.' + v1 })
      .localeCompare(intl.formatMessage({ id: 'tags.' + v2 }));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'tagsMapping.reference' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: param => {
        return intl.formatMessage({ id: 'tags.' + param.row.name });
      },
      sortComparator: nameTranslatedComparator,
    },
    {
      field: 'tag',
      headerName: intl.formatMessage({ id: 'tagsMapping.pitag' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'constant',
      headerName: intl.formatMessage({ id: 'tagsMapping.constant' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'lastModification',
      headerName: intl.formatMessage({ id: 'tagsMapping.lastModification' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const date = new Date(params.value);
        return params.value ? getFormattedDateWithMinute(date) : '';
      },
    },
    {
      field: 'lowerBound',
      headerName: intl.formatMessage({ id: 'tagsMapping.lowerBound' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'upperBound',
      headerName: intl.formatMessage({ id: 'tagsMapping.upperBound' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'default',
      headerName: intl.formatMessage({ id: 'tagsMapping.default' }),
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      flex: 1.5,
    },
    {
      field: 'copy',
      headerName: intl.formatMessage({ id: 'tagsMapping.copy' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: param => {
        return (
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={tooltipValue[site.tags.findIndex(r => r === param.row)]}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onMouseLeave={handleTooltipClose}
            title="Copied!"
          >
            <IconButton
              color="actionButton"
              onClick={() => {
                copyData(param.row);
                handleTooltipOpen(site.tags.findIndex(r => r === param.row));
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div className={clsx(styles.tagMappingPageContainer, menuStatus && styles.menuOn)}>
      <CustomCard border="undefined">
        <CardContent className={styles.parent_gap_container}>
          <div className={styles.tagTitle}>
            <h1>
              <FormattedMessage id="tagsMapping.titlePopin" />
            </h1>
            {isAdmin && (
              <CustomButton
                type="button"
                variant="contained"
                className={styles.buttonContainer}
                onClick={handlePopinOpen}
                disabled={site.name === ''}
                startIcon={<EditOutlinedIcon />}
              >
                <FormattedMessage id="tagsMapping.title" />
              </CustomButton>
            )}
            {open === true && (
              <EditTagsPopin isOpen={open} handleClose={handlePopinClose} newTags={site.tags} />
            )}
          </div>
          <div className={styles.information}>
            <FormattedMessage id="tagsMapping.description" values={{ br: <br /> }} />
          </div>
          <StripedDataGrid
            disableColumnMenu
            disableColumnFilter
            disableRowSelectionOnClick
            sortingOrder={['desc', 'asc']}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: '-60px !important',
                  minWidth: '650px !important',
                },
              },
            }}
            sx={{
              borderRadius: '16px',
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
            }}
            rows={site.tags.filter(tag => tag.name !== 'timestamp')}
            columns={columns}
            getRowId={row => row.name + row.tag}
            getRowClassName={params =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            pageSizeOptions={[10]}
          />
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default TagMapping;
