import { FormControlLabel, Dialog, DialogContent, DialogTitle, Switch } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../../corelogic/redux/site/site.slice';
import styles from './updateAdminPopin.module.scss';
import { Admin } from '../../../../../../corelogic/domain/models/site/admins.model';
import { FormattedMessage } from 'react-intl';
import { CustomButton, CustomTextField } from '../../../../../../util/functionUtil';

type Props = {
  index?: number;
  selectedAdmin?: Admin;
  isOpen: boolean;
  handleClose: () => void;
};

const UpdateAdminPopin: React.FC<Props> = ({ selectedAdmin, isOpen, handleClose }) => {
  const { site } = useSelector((state: RootState) => state.site);
  const dispatch = useDispatch();

  const [nameState, setNameState] = useState<string | null>(selectedAdmin?.name ?? null);
  const [jobTitleState, setJobTitleState] = useState<string | null>(
    selectedAdmin?.jobTitle ?? null,
  );
  const [mailState, setMailState] = useState<string | null>(selectedAdmin?.mail ?? null);
  const [mail_subscriberState, setMail_subscriberState] = useState<boolean>(
    selectedAdmin?.mail_subscriber ?? false,
  );
  function resetState(): void {
    setNameState(null);
    setJobTitleState(null);
    setMailState(null);
    setMail_subscriberState(false);
  }

  function updateSiteAdmin(): void {
    if (
      nameState == null ||
      jobTitleState == null ||
      mailState == null ||
      mail_subscriberState == null
    )
      return;
    const updatedAdmin: Admin = {
      name: nameState,
      jobTitle: jobTitleState,
      mail: mailState,
      mail_subscriber: mail_subscriberState,
    };

    const siteAdmins = [...site.admins];
    const oldAdminIndex = siteAdmins.findIndex(e => e == selectedAdmin);
    siteAdmins[oldAdminIndex] = updatedAdmin;
    const updateBodyAdmins = { admins: siteAdmins };
    dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: updateBodyAdmins }));
    resetState();
    handleClose();
  }

  const handleChangeSwitch = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setMail_subscriberState(event.target.checked);
  };
  return (
    <Dialog
      className={styles.dialogContainer}
      autoFocus
      id="updateAdminPopin"
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '90%', width: '50%' },
      }}
    >
      <DialogTitle>
        <div className={styles.titleAndButtonsPopin}>
          <div>
            <FormattedMessage id="admin.editAdmin" />
          </div>
          <div className={styles.buttonContainerTags}>
            <div className={styles.buttonTags}>
              <CustomButton variant="outlined" type="button" onClick={handleClose} color="primary">
                <FormattedMessage id="button.cancel" />
              </CustomButton>
            </div>
            <div className={styles.buttonTags}>
              <CustomButton
                variant="contained"
                type="button"
                onClick={updateSiteAdmin}
                color="primary"
                disabled={nameState == '' || jobTitleState == '' || mailState == ''}
              >
                <FormattedMessage id="button.publish" />
              </CustomButton>
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.mandatoryTextContainer}>
          <div>
            <FormattedMessage id="popin.field" />
          </div>
          <div style={{ color: 'red' }}> * </div>
          <div>
            <FormattedMessage id="popin.mandatoryField" />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div>
            <div className={styles.infoTitleContainer}>
              <div style={{ color: 'red' }}> * </div>
              <FormattedMessage id="admin.name" />
            </div>
            <CustomTextField
              fullWidth
              multiline
              defaultValue={selectedAdmin?.name}
              onChange={e => setNameState(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div>
            <div className={styles.infoTitleContainer}>
              <div style={{ color: 'red' }}> * </div>
              <FormattedMessage id="admin.jobTitle" />
            </div>
            <CustomTextField
              fullWidth
              multiline
              defaultValue={selectedAdmin?.jobTitle}
              onChange={e => setJobTitleState(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div>
            <div className={styles.infoTitleContainer}>
              <div style={{ color: 'red' }}> * </div>
              <FormattedMessage id="admin.mail" />
            </div>
            <CustomTextField
              fullWidth
              multiline
              defaultValue={selectedAdmin?.mail}
              onChange={e => setMailState(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div>
            <div className={styles.infoTitleContainer}>
              <div style={{ color: 'red' }}> * </div>
              <FormattedMessage id="admin.mail_subscriber" />
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={mail_subscriberState}
                  onChange={handleChangeSwitch}
                  name="mailSubscriber"
                  color="primary"
                />
              }
              label="Mail Subscriber"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAdminPopin;
